<template>
  <v-dialog :value="value" persistent width="500">
    <v-card color="#fafafa">
      <v-toolbar dark flat color="#1C3144">
        <v-toolbar-title>Output Retail Credits Report</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="ma-0 pa-0" fluid>
        <v-data-table
          :headers="headers"
          :items-per-page="-1"
          :items="items"
          :loading="loading"
          :search="search"
          item-key="key_value"
          dense
          fixed-header
          height="350"
          hide-default-footer
          show-select
          v-model="selected"
        >

        <template #top>
          <v-toolbar flat color="#fafafa">
            <v-text-field class="mt-7" dense outlined background-color="white" label="Filter Stores" prepend-inner-icon="mdi-filter-outline" v-model="search"/>
          </v-toolbar>
        </template>
        </v-data-table>
        <v-divider/>
        <v-toolbar flat color="#fafafa">
          <v-toolbar-title v-if="selected.length">{{selected.length}} Selected</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="!selected.length" color="#0c67a5" class="white--text" @click="runReport">
            Run Report
            <v-icon right>mdi-play-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RetailCreditStores',

  props: ['value', 'reportId', 'selectedBatch'],

  data() {
    return {
      items: [],
      loading: true,
      selected: [],
      search: ''
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Store Name', value: 'display_value'}
      ]
    },

    allStores () {
      return this.items.length === this.selected.length
    }
  },

  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value) {
          this.getRequiredParams()
        }
      }
    }
  },

  methods: {
    remove (item) {
      const index = this.selected.indexOf(item.key_value)
      if (index >= 0) this.selected.splice(index, 1)
    },

    async getRequiredParams () {
      await this.$reports.getParameters(this.reportId)
        .then(response => {
          this.requiredParameters = response.parameters
          this.getDimensions()
        })
    },

    async getDimensions () {
      await this.requiredParameters.forEach(param => {
        if (param.data_type === 'dimension') {
          this.$reports.getDimensions(param.field_id)
            .then(response => {
              this.items = response
              this.loading = false
            })
        }
      })
    },

    async runReport () {
      var params = new URLSearchParams()
      params.append('batch_number', this.selectedBatch.batchNumber)
      if (!this.allStores) {
        this.selected.map(store => {
          params.append('store_ids', store.key_value)
        })
      }

      const reportParams = params
      await this.$reports.submitReport(163, reportParams)
        .then(() => {
          this.$emit('dialog')
          this.$emit('update:value', false)
        }).catch(() => {
          this.notify('error', 'Failed to Submit Report')
        })
    }
  }
}
</script>

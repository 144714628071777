var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "500" } },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c("v-toolbar-title", [_vm._v("Output Retail Credits Report")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  "items-per-page": -1,
                  items: _vm.items,
                  loading: _vm.loading,
                  search: _vm.search,
                  "item-key": "key_value",
                  dense: "",
                  "fixed-header": "",
                  height: "350",
                  "hide-default-footer": "",
                  "show-select": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "", color: "#fafafa" } },
                          [
                            _c("v-text-field", {
                              staticClass: "mt-7",
                              attrs: {
                                dense: "",
                                outlined: "",
                                "background-color": "white",
                                label: "Filter Stores",
                                "prepend-inner-icon": "mdi-filter-outline"
                              },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              }),
              _c("v-divider"),
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "#fafafa" } },
                [
                  _vm.selected.length
                    ? _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.selected.length) + " Selected")
                      ])
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        disabled: !_vm.selected.length,
                        color: "#0c67a5"
                      },
                      on: { click: _vm.runReport }
                    },
                    [
                      _vm._v(" Run Report "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-play-circle-outline")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }